
// Normalize CSS
@import ~normalize.css

// Yerani Icons
@import ./assets/fonts/yerani-icons/index.css

:root
  --primary-color: #1ab1ff
  --accent-color: #f8ad92
  --background-color: #ebebeb
  --banner: #8ecdf3


*
  box-sizing: border-box
  font-family: 'Fedra Sans Arm', 'Fedra Sans Pro'

button
  font-family: 'Fedra Sans Arm', 'Fedra Sans Pro', !important
  &:focus
    outline: none

body
  max-width: 100%
  background-color: var(--background-color)
  color: #151b1a
  overflow-x: hidden

.row
  display: flex
  flex-direction: row

.column
  display: flex
  flex-direction: column

.container
  width: 100%
  padding-right: 15px
  padding-left: 15px
  margin-right: auto
  margin-left: auto
  @media screen and (min-width: 768px)
    width: 750px
  @media screen and (min-width: 992px)
    width: 970px
  @media screen and (min-width: 1200px)
    width: 1170px

.invisible
  visibility: hidden  

.static-page-title
  width: 100%
  padding: 24px
  background-color: var(--primary-color)
  color: #fff
  text-align: center
  text-transform: uppercase
  letter-spacing: 2px
  word-spacing: 5px
  font-size: 28px

.cdk-overlay-pane
  max-width: 1170px !important

.amd
  font-family: sans-serif !important

// Scrollbar
::-webkit-scrollbar
  width: 8px
  height: 5px

::-webkit-scrollbar-track
  box-shadow: none
  border-radius: 10px

::-webkit-scrollbar-thumb
  background: #747474
  border-radius: 10px

::-webkit-scrollbar-thumb:hover
  background: #747474

// NGX TIMEPICKER
.timepicker-overlay
  z-index: 9999 !important
  .timepicker__header
    background-color: var(--primary-color) !important

.mapboxgl-control-container
  display: none !important


// FONTS
// Fedra Sans Arm
@font-face
  font-family: 'Fedra Sans Arm'
  src: url('./assets/fonts/fedra-sans-arm/FedraSansArm-Book.woff2') format('woff2'),
  src: url('./assets/fonts/fedra-sans-arm/FedraSansArm-Book.woff') format('woff')
  font-weight: normal
  font-style: normal
  font-display: swap


@font-face
  font-family: 'Fedra Sans Arm'
  src: url('/assets/fonts/fedra-sans-arm/FedraSansArm-Medium.woff2') format('woff2'),
  src: url('/assets/fonts/fedra-sans-arm/FedraSansArm-Medium.woff') format('woff')
  font-weight: 500
  font-style: normal
  font-display: swap


@font-face
  font-family: 'Fedra Sans Arm'
  src: url('/assets/fonts/fedra-sans-arm/FedraSansArm-Light.woff2') format('woff2'),
  src: url('/assets/fonts/fedra-sans-arm/FedraSansArm-Light.woff') format('woff')
  font-weight: 300
  font-style: normal
  font-display: swap


@font-face
  font-family: 'Fedra Sans Arm'
  src: url('/assets/fonts/fedra-sans-arm/FedraSansArm-Bold.woff2') format('woff2'),
  src: url('/assets/fonts/fedra-sans-arm/FedraSansArm-Bold.woff') format('woff')
  font-weight: bold
  font-style: normal
  font-display: swap


@font-face
  font-family: 'Fedra Sans Arm'
  src: url('/assets/fonts/fedra-sans-arm/FedraSansArm-BoldItalic.woff2') format('woff2'),
  src: url('/assets/fonts/fedra-sans-arm/FedraSansArm-BoldItalic.woff') format('woff')
  font-weight: bold
  font-style: italic
  font-display: swap


@font-face
  font-family: 'Fedra Sans Arm'
  src: url('/assets/fonts/fedra-sans-arm/FedraSansArm-DemiItalic.woff2') format('woff2'),
  src: url('/assets/fonts/fedra-sans-arm/FedraSansArm-DemiItalic.woff') format('woff')
  font-weight: normal
  font-style: italic
  font-display: swap

@font-face
  font-family: 'Fedra Sans Arm'
  src: url('/assets/fonts/fedra-sans-arm/FedraSansArm-MediumItalic.woff2') format('woff2'),
  src: url('/assets/fonts/fedra-sans-arm/FedraSansArm-MediumItalic.woff') format('woff')
  font-weight: 500
  font-style: italic
  font-display: swap

@font-face
  font-family: 'Fedra Sans Arm'
  src: url('/assets/fonts/fedra-sans-arm/FedraSansArm-BookItalic.woff2') format('woff2'),
  src: url('/assets/fonts/fedra-sans-arm/FedraSansArm-BookItalic.woff') format('woff')
  font-weight: normal
  font-style: italic
  font-display: swap

@font-face
  font-family: 'Fedra Sans Arm'
  src: url('/assets/fonts/fedra-sans-arm/FedraSansArm-LightItalic.woff2') format('woff2'),
  src: url('/assets/fonts/fedra-sans-arm/FedraSansArm-LightItalic.woff') format('woff')
  font-weight: 300
  font-style: italic
  font-display: swap

@font-face
  font-family: 'Fedra Sans Arm'
  src: url('/assets/fonts/fedra-sans-arm/FedraSansArm-Demi.woff2') format('woff2'),
  src: url('/assets/fonts/fedra-sans-arm/FedraSansArm-Demi.woff') format('woff')
  font-weight: normal
  font-style: normal
  font-display: swap

// Fedra Sans Pro
@font-face
  font-family: 'Fedra Sans Pro'
  src: url('/assets/fonts/fedra-sans-pro/FedraSansPro-Medium.eot')
  src: local('Fedra Sans Pro Medium'), local('/assets/fonts/fedra-sans-pro/FedraSansPro-Medium'),
  src: url('/assets/fonts/fedra-sans-pro/FedraSansPro-Medium.eot?#iefix') format('embedded-opentype'),
  src: url('/assets/fonts/fedra-sans-pro/FedraSansPro-Medium.woff2') format('woff2'),
  src: url('/assets/fonts/fedra-sans-pro/FedraSansPro-Medium.woff') format('woff'),
  font-weight: 500
  font-style: normal

@font-face
  font-family: 'Fedra Sans Pro Book'
  src: url('/assets/fonts/fedra-sans-pro/FedraSansPro-BookItalic.eot')
  src: local('Fedra Sans Pro Book Italic'), local('/assets/fonts/fedra-sans-pro/FedraSansPro-BookItalic'),
  src: url('/assets/fonts/fedra-sans-pro/FedraSansPro-BookItalic.eot?#iefix') format('embedded-opentype'),
  src: url('/assets/fonts/fedra-sans-pro/FedraSansPro-BookItalic.woff2') format('woff2'),
  src: url('/assets/fonts/fedra-sans-pro/FedraSansPro-BookItalic.woff') format('woff'),
  font-weight: normal
  font-style: italic

@font-face
  font-family: 'Fedra Sans Pro'
  src: url('/assets/fonts/fedra-sans-pro/FedraSansPro-MediumItalic.eot')
  src: local('Fedra Sans Pro Medium Italic'), local('/assets/fonts/fedra-sans-pro/FedraSansPro-MediumItalic'),
  src: url('/assets/fonts/fedra-sans-pro/FedraSansPro-MediumItalic.eot?#iefix') format('embedded-opentype'),
  src: url('/assets/fonts/fedra-sans-pro/FedraSansPro-MediumItalic.woff2') format('woff2'),
  src: url('/assets/fonts/fedra-sans-pro/FedraSansPro-MediumItalic.woff') format('woff'),
  font-weight: 500
  font-style: italic

@font-face
  font-family: 'Fedra Sans Pro'
  src: url('/assets/fonts/fedra-sans-pro/FedraSansPro-BoldItalic.eot')
  src: local('Fedra Sans Pro Bold Italic'), local('/assets/fonts/fedra-sans-pro/FedraSansPro-BoldItalic'),
  src: url('/assets/fonts/fedra-sans-pro/FedraSansPro-BoldItalic.eot?#iefix') format('embedded-opentype'),
  src: url('/assets/fonts/fedra-sans-pro/FedraSansPro-BoldItalic.woff2') format('woff2'),
  src: url('/assets/fonts/fedra-sans-pro/FedraSansPro-BoldItalic.woff') format('woff'),
  font-weight: bold
  font-style: italic

@font-face
  font-family: 'Fedra Sans Pro'
  src: url('/assets/fonts/fedra-sans-pro/FedraSansPro-Bold.eot')
  src: local('Fedra Sans Pro Bold'), local('/assets/fonts/fedra-sans-pro/FedraSansPro-Bold'),
  src: url('/assets/fonts/fedra-sans-pro/FedraSansPro-Bold.eot?#iefix') format('embedded-opentype'),
  src: url('/assets/fonts/fedra-sans-pro/FedraSansPro-Bold.woff2') format('woff2'),
  src: url('/assets/fonts/fedra-sans-pro/FedraSansPro-Bold.woff') format('woff'),
  font-weight: bold
  font-style: normal

@font-face
  font-family: 'Fedra Sans Pro'
  src: url('/assets/fonts/fedra-sans-pro/FedraSansPro-LightItalic.eot')
  src: local('Fedra Sans Pro Light Italic'), local('/assets/fonts/fedra-sans-pro/FedraSansPro-LightItalic'),
  src: url('/assets/fonts/fedra-sans-pro/FedraSansPro-LightItalic.eot?#iefix') format('embedded-opentype'),
  src: url('/assets/fonts/fedra-sans-pro/FedraSansPro-LightItalic.woff2') format('woff2'),
  src: url('/assets/fonts/fedra-sans-pro/FedraSansPro-LightItalic.woff') format('woff'),
  font-weight: 300
  font-style: italic

@font-face
  font-family: 'Fedra Sans Pro Book'
  src: url('/assets/fonts/fedra-sans-pro/FedraSansPro-Book.eot')
  src: local('Fedra Sans Pro Book'), local('/assets/fonts/fedra-sans-pro/FedraSansPro-Book'),
  src: url('/assets/fonts/fedra-sans-pro/FedraSansPro-Book.eot?#iefix') format('embedded-opentype'),
  src: url('/assets/fonts/fedra-sans-pro/FedraSansPro-Book.woff2') format('woff2'),
  src: url('/assets/fonts/fedra-sans-pro/FedraSansPro-Book.woff') format('woff'),
  font-weight: normal
  font-style: normal

@font-face
  font-family: 'Fedra Sans Pro Normal'
  src: url('/assets/fonts/fedra-sans-pro/FedraSansPro-Normal.eot')
  src: local('Fedra Sans Pro Normal'), local('/assets/fonts/fedra-sans-pro/FedraSansPro-Normal'),
  src: url('/assets/fonts/fedra-sans-pro/FedraSansPro-Normal.eot?#iefix') format('embedded-opentype'),
  src: url('/assets/fonts/fedra-sans-pro/FedraSansPro-Normal.woff2') format('woff2'),
  src: url('/assets/fonts/fedra-sans-pro/FedraSansPro-Normal.woff') format('woff'),
  font-weight: normal
  font-style: normal

@font-face
  font-family: 'Fedra Sans Pro Normal'
  src: url('/assets/fonts/fedra-sans-pro/FedraSansPro-NormalItalic.eot')
  src: local('Fedra Sans Pro Normal Italic'), local('/assets/fonts/fedra-sans-pro/FedraSansPro-NormalItalic'),
  src: url('/assets/fonts/fedra-sans-pro/FedraSansPro-NormalItalic.eot?#iefix') format('embedded-opentype'),
  src: url('/assets/fonts/fedra-sans-pro/FedraSansPro-NormalItalic.woff2') format('woff2'),
  src: url('/assets/fonts/fedra-sans-pro/FedraSansPro-NormalItalic.woff') format('woff'),
  font-weight: normal
  font-style: italic

@font-face
  font-family: 'Fedra Sans Pro'
  src: url('/assets/fonts/fedra-sans-pro/FedraSansPro-Light.eot')
  src: local('Fedra Sans Pro Light'), local('/assets/fonts/fedra-sans-pro/FedraSansPro-Light'),
  src: url('/assets/fonts/fedra-sans-pro/FedraSansPro-Light.eot?#iefix') format('embedded-opentype'),
  src: url('/assets/fonts/fedra-sans-pro/FedraSansPro-Light.woff2') format('woff2'),
  src: url('/assets/fonts/fedra-sans-pro/FedraSansPro-Light.woff') format('woff'),
  font-weight: 300
  font-style: normal

