@font-face {
  font-family: 'yerani-icons';
  src: url('../font/yerani-icons.eot?18088900');
  src: url('../font/yerani-icons.eot?18088900#iefix') format('embedded-opentype'),
       url('../font/yerani-icons.woff2?18088900') format('woff2'),
       url('../font/yerani-icons.woff?18088900') format('woff'),
       url('../font/yerani-icons.ttf?18088900') format('truetype'),
       url('../font/yerani-icons.svg?18088900#yerani-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'yerani-icons';
    src: url('../font/yerani-icons.svg?18088900#yerani-icons') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "yerani-icons";
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-heart-filled:before { content: '\e800'; } /* '' */
.icon-heart-empty:before { content: '\e801'; } /* '' */
.icon-person:before { content: '\e802'; } /* '' */
.icon-shopping-cart:before { content: '\e803'; } /* '' */
.icon-trash:before { content: '\e804'; } /* '' */
.icon-phone:before { content: '\e805'; } /* '' */
.icon-right-small:before { content: '\e806'; } /* '' */
.icon-left-small:before { content: '\e807'; } /* '' */
.icon-calendar:before { content: '\e808'; } /* '' */
.icon-up-small:before { content: '\e809'; } /* '' */
.icon-down-small:before { content: '\e80a'; } /* '' */
.icon-cancel:before { content: '\e80b'; } /* '' */
.icon-logout:before { content: '\e80c'; } /* '' */
.icon-payment:before { content: '\e80d'; } /* '' */
.icon-address:before { content: '\e80e'; } /* '' */
.icon-contact:before { content: '\e80f'; } /* '' */
.icon-mobile:before { content: '\e810'; } /* '' */
.icon-search-old:before { content: '\e811'; } /* '' */
.icon-ok:before { content: '\e812'; } /* '' */
.icon-mail:before { content: '\e813'; } /* '' */
.icon-delivery:before { content: '\e814'; } /* '' */
.icon-basket:before { content: '\e815'; } /* '' */
.icon-heart:before { content: '\e816'; } /* '' */
.icon-search:before { content: '\e817'; } /* '' */
.icon-ios-share:before { content: '\e818'; } /* '' */
.icon-down-open:before { content: '\f004'; } /* '' */
.icon-up-open:before { content: '\f005'; } /* '' */
.icon-right-open:before { content: '\f006'; } /* '' */
.icon-left-open:before { content: '\f007'; } /* '' */
.icon-menu:before { content: '\f008'; } /* '' */
.icon-cancel-circled:before { content: '\f06e'; } /* '' */
.icon-facebook:before { content: '\f09a'; } /* '' */
.icon-mail-alt:before { content: '\f0e0'; } /* '' */
.icon-chat-empty:before { content: '\f0e6'; } /* '' */
.icon-instagram:before { content: '\f16d'; } /* '' */
.icon-plus-squared-alt:before { content: '\f196'; } /* '' */
.icon-newspaper:before { content: '\f1ea'; } /* '' */
.icon-whatsapp:before { content: '\f232'; } /* '' */
.icon-safari:before { content: '\f267'; } /* '' */
.icon-telegram:before { content: '\f2c6'; } /* '' */
