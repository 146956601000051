
.icon-heart-filled:before { content: '\e800'; } /* '' */
.icon-heart-empty:before { content: '\e801'; } /* '' */
.icon-person:before { content: '\e802'; } /* '' */
.icon-shopping-cart:before { content: '\e803'; } /* '' */
.icon-trash:before { content: '\e804'; } /* '' */
.icon-phone:before { content: '\e805'; } /* '' */
.icon-right-small:before { content: '\e806'; } /* '' */
.icon-left-small:before { content: '\e807'; } /* '' */
.icon-calendar:before { content: '\e808'; } /* '' */
.icon-up-small:before { content: '\e809'; } /* '' */
.icon-down-small:before { content: '\e80a'; } /* '' */
.icon-cancel:before { content: '\e80b'; } /* '' */
.icon-logout:before { content: '\e80c'; } /* '' */
.icon-payment:before { content: '\e80d'; } /* '' */
.icon-address:before { content: '\e80e'; } /* '' */
.icon-contact:before { content: '\e80f'; } /* '' */
.icon-mobile:before { content: '\e810'; } /* '' */
.icon-search-old:before { content: '\e811'; } /* '' */
.icon-ok:before { content: '\e812'; } /* '' */
.icon-mail:before { content: '\e813'; } /* '' */
.icon-delivery:before { content: '\e814'; } /* '' */
.icon-basket:before { content: '\e815'; } /* '' */
.icon-heart:before { content: '\e816'; } /* '' */
.icon-search:before { content: '\e817'; } /* '' */
.icon-ios-share:before { content: '\e818'; } /* '' */
.icon-down-open:before { content: '\f004'; } /* '' */
.icon-up-open:before { content: '\f005'; } /* '' */
.icon-right-open:before { content: '\f006'; } /* '' */
.icon-left-open:before { content: '\f007'; } /* '' */
.icon-menu:before { content: '\f008'; } /* '' */
.icon-cancel-circled:before { content: '\f06e'; } /* '' */
.icon-facebook:before { content: '\f09a'; } /* '' */
.icon-mail-alt:before { content: '\f0e0'; } /* '' */
.icon-chat-empty:before { content: '\f0e6'; } /* '' */
.icon-instagram:before { content: '\f16d'; } /* '' */
.icon-plus-squared-alt:before { content: '\f196'; } /* '' */
.icon-newspaper:before { content: '\f1ea'; } /* '' */
.icon-whatsapp:before { content: '\f232'; } /* '' */
.icon-safari:before { content: '\f267'; } /* '' */
.icon-telegram:before { content: '\f2c6'; } /* '' */
